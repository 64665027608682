import React, { useEffect, useState } from 'react'
import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const ErrorInvalidHandler = ({ onResponse }: Props) => {
  return (
    <>
      <h1>
        <span>Ooops!</span>
        Não deu certo dessa vez
      </h1>
      <hr />
      <p>
        Não conseguimos validar todos os pontos para garantir a leitura da sua
        face. Poderia verificar as instruções e tentar novamente, por favor?
      </p>

      <ul className="instruction-list list-group">
        <li className="list-group-item">
          <i>
            <BsSun />
          </i>
          Encontre um local iluminado e com fundo neutro, como uma parede branca
        </li>
        <li className="list-group-item">
          <i>
            <BsPersonBoundingBox />
          </i>
          Encaixe a imagem do rosto na moldura
        </li>
        <li className="list-group-item">
          <i>
            <BsPeople />
          </i>
          Certifique-se de que não há outra pessoa no campo de captura
        </li>
        <li className="list-group-item">
          <i>
            <BsEmojiWink />
          </i>
          Não sorria e não pisque os olhos
        </li>
        <li className="list-group-item">
          <i>
            <BsEmojiSunglasses />
          </i>
          Não utilize óculos, máscaras ou bonés.
        </li>
      </ul>
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'faceproceed' })}
        >
          Refazer
        </a>
      </div>
    </>
  )
}

export default ErrorInvalidHandler
