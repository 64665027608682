import React, { Component, useCallback, useEffect, useState } from 'react'
import './OnboardCard.sass'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'

interface Props {
  onResponse: (response: any) => void
}

declare global {
  interface Window {
    constraints: any
    stream: any
  }
}

const constraints = (window.constraints = {
  video: true
})

function FaceInstructions({ onResponse }: Props) {

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
    clearErrors
  } = useForm()
  
  const [photoState, setphotoState] = useState<string>('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  const handleSuccess = (stream: any) => {
    setUserHasCamera(true)
    const videoTracks = stream.getVideoTracks()
  }

  const onSubmit = (data: any) => {
    onResponse({ step: 'faceproceed' })
    console.log(data)
  }

  return (
    <>
      <div className={`${photoState} card`}>
        {userHasCamera ? (
          <div className="have-camera">
            <h1>
              Algumas <span>dicas</span>
            </h1>
            <ul className="instruction-list list-group">
              <li className="list-group-item">
                <i>
                  <BsSun />
                </i>
                Encontre um local iluminado e com fundo neutro, como uma parede
                branca
              </li>
              <li className="list-group-item">
                <i>
                  <BsPersonBoundingBox />
                </i>
                Encaixe a imagem do rosto na moldura
              </li>
              <li className="list-group-item">
                <i>
                  <BsPeople />
                </i>
                Certifique-se de que não há outra pessoa no campo de captura
              </li>
              <li className="list-group-item">
                <i>
                  <BsEmojiWink />
                </i>
                Não sorria e não pisque os olhos
              </li>
              <li className="list-group-item">
                <i>
                  <BsEmojiSunglasses />
                </i>
                Não utilize óculos, máscaras ou bonés.
              </li>
            </ul>
            <div className="form-group form-submit">
            <Form onSubmit={handleSubmit(onSubmit)}>
            
            <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input form-control-sm"
                  {...register('valid', { required: true })}
                  id="valid"
                />
                <label className="form-check-label" htmlFor="valid">
                  Eu aceito os{' '}
                  <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">
                    termos de uso </a> e <a href="/fpf_termos_privacidade.pdf" rel="noreferrer" target="_blank"> politica de privacidade
                  </a>{' '}
                  para o uso BePass
                </label>
                {errors.valid && (
                  <span className="label-error">
                    Você precisa aceitar os termos de uso
                  </span>
                )}
              </div>
            <div className="form-group form-submit">
              {/* <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => onResponse({ step: 'faceproceed' })}
              >
             
              </a> */}
              <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="   Vamos lá!"
              onClick={() => clearErrors()}
            />
            </div>
            </Form>
              {/* <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => onResponse({ step: 'faceproceed' })}
              >
                Vamos lá!
              </a> */}
            </div>
          </div>
        ) : (
          <div className="no-camera">
            {' '}
            <h1>
              Não achamos <span>sua câmera</span>
            </h1>
            <p>
              Não conseguimos acessar sua câmera para prosseguir. <br />
              Por favor, revise as permissões do seu aplicativo ou tente com
              outro celular
            </p>
            <div className="form-group form-submit">
              <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => window.location.reload()}
              >
                Recarregar
              </a>
            </div>
          </div>
        )}
      </div>
      <ul className="step-counter">
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
        <li></li>
      </ul>
    </>
  )
}

export default FaceInstructions
